@import "./variables";
@import "./mixins";

// SITE TYPOG
@mixin heading-1($fontSize: 56px) {
  @include letter-spacing($fontSize, 0.06);
  font-family: $ff-merriweather;
  font-weight: 700;
  font-size: $fontSize;
  line-height: 115%;
}

@mixin heading-2($fontSize: 42px) {
  @include letter-spacing($fontSize, 0.03);
  font-family: $ff-merriweather;
  font-weight: 300;
  font-size: $fontSize;
  line-height: 133%;
}

@mixin heading-3($fontSize: 32px) {
  @include letter-spacing($fontSize, 0.03);
  font-family: $ff-merriweather;
  font-weight: 700;
  font-size: $fontSize;
}

@mixin heading-3-light($fontSize: 32px) {
  @include letter-spacing($fontSize, 0.03);
  font-family: $ff-merriweather;
  font-weight: 300;
  font-size: $fontSize;
  line-height: 150%;
}

@mixin heading-4($fontSize: 24px) {
  @include letter-spacing($fontSize, 0.03);
  font-family: $ff-raleway;
  font-weight: 300;
  font-size: $fontSize;
  line-height: 133%;
}

@mixin heading-4-semi-bold($fontSize: 24px) {
  @include letter-spacing($fontSize, 0.03);
  font-family: $ff-raleway;
  font-weight: 600;
  font-size: $fontSize;
  line-height: 167%;
}

@mixin heading-5($fontSize: 18px) {
  font-family: $ff-raleway;
  font-weight: 300;
  font-size: $fontSize;
  line-height: 133%;
}

// MENU TYPOG
@mixin heading-1-menu($fontSize: 56px) {
  @include letter-spacing($fontSize, 0.06);
  font-family: $ff-raleway;
  font-weight: 400;
  font-size: $fontSize;
  line-height: 115%;
}

@mixin heading-3-menu($fontSize: 32px) {
  @include letter-spacing($fontSize, 0.06);
  font-family: $ff-raleway;
  font-weight: 300;
  font-size: $fontSize;
}
