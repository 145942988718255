@mixin animation(
  $name,
  $timing: 200ms,
  $animationDelay: 0ms,
  $easing: cubic-bezier(0.39, 0.575, 0.565, 1),
  $iterations: 1
) {
  animation: $name $timing forwards $animationDelay $easing $iterations;
}

// GENERAL KEYFRAMES
@keyframes fade-up {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-left {
  0% {
    opacity: 0;
    transform: translateX(50%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-right {
  0% {
    opacity: 0;
    transform: translateX(-50%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}

// MENU ICON KEYFRAMES
@keyframes menu-icon-top-open {
  0% {
    transform: translateY(0);
  }

  30%,
  70% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(-10px) rotate(45deg) translateX(14px) scaleX(0.8)
      translateZ(0);
  }
}

@keyframes menu-icon-mid-open {
  0% {
    transform: translateY(0);
  }

  30%,
  70% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(14px) rotate(-45deg) translateX(-2px) scaleX(0.8)
      translateZ(0);
  }
}

@keyframes menu-icon-bot-open {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  50%,
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}

@keyframes menu-icon-top-close {
  0% {
    transform: translateY(-10px) rotate(45deg) translateX(14px) scaleX(0.8)
      translateZ(0);
  }

  100% {
    transform: translateY(0) rotate(0) translateX(0) scaleX(1) translateZ(0);
  }
}

@keyframes menu-icon-mid-close {
  0% {
    transform: translateY(14px) rotate(-45deg) translateX(-2px) scaleX(0.8)
      translateZ(0);
  }

  100% {
    transform: translateY(0) rotate(0) translateX(0) scaleX(1) translateZ(0);
  }
}

@keyframes menu-icon-bot-close {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

// Loader
@keyframes bar-1-slide {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -35%;
  }
}

@keyframes bar-2-slide {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  100% {
    left: 107%;
    right: -8%;
  }
}
