@import "./variables";

// RESETS
@mixin button-reset {
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
}

@mixin ul-reset {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

// SHADOWS
@mixin box-shadow-2 {
  box-shadow: $shadow-lg-blur;
}

// GRADIENTS
@mixin lin-gradient-black-bot($blackOpacity: 0.8, $transparentPos: 40%) {
  background-image: linear-gradient(
    transparent $transparentPos,
    rgba($black-dark, $blackOpacity)
  );
}

// LAYOUT
@mixin site-width-container($width: 1320px) {
  width: $width;
  max-width: 100%;
  margin: 0 auto;
}

// LETTER SPACING
@mixin letter-spacing($fontsize, $percent) {
  letter-spacing: calc(#{$fontsize} * #{$percent});
}

// LINKS
@mixin link($color, $hover-color, $hoverOpacity: 1) {
  outline: none;
  color: $color;
  &:hover,
  &:focus {
    color: $hover-color;
    opacity: $hoverOpacity;
  }
}

// IMAGE/VIDEO
@mixin image-container($containerPos: relative, $childPos: relative) {
  overflow: hidden;
  position: $containerPos;
  img,
  video {
    position: $childPos;
    width: 100%;
    object-fit: cover;
  }
}

@mixin aspect-ratio-container($y, $x, $width) {
  padding-top: calc((#{$y} / #{$x}) * 100%);
  height: 0;
  width: $width;
}

// GENERAL
@mixin pseudo(
  $position: absolute,
  $content: "",
  $top: 0,
  $left: 0,
  $width: 100%,
  $height: 100%
) {
  position: $position;
  content: $content;
  top: $top;
  left: $left;
  width: $width;
  height: $height;
}

// ARROW ANIMATION
@mixin arrow-animation {
  &:hover svg {
    transform: translateX(10px) translateZ(0);
    transition: all 200ms cubic-bezier(0.39, 0.575, 0.565, 1);
  }
}

@mixin svg-arrow-animation {
  transition: all 200ms cubic-bezier(0.39, 0.575, 0.565, 1);
  transform: translateX(0px) translateZ(0);
}

// BUTTONS
@mixin button-base {
  padding: 15px;
  cursor: pointer;
  font-family: $ff-raleway;
  font-weight: 600;
}

@mixin button-solid(
  $color,
  $background,
  $fontSize,
  $letterSpacing,
  $hoverBackground,
  $hoverColor: $color
) {
  @include button-base;
  @include letter-spacing($fontSize, $letterSpacing);
  color: $color;
  border: none;
  font-size: $fontSize;
  background-color: $background;
  &:hover {
    background-color: $hoverBackground;
    color: $hoverColor;
  }
}

@mixin button-outline(
  $color,
  $background,
  $borderColor,
  $fontSize,
  $letterSpacing,
  $borderSize: 3px,
  $hoverColor: $white
) {
  @include button-base;
  @include letter-spacing($fontSize, $letterSpacing);
  color: $color;
  border: $borderSize solid $borderColor;
  font-size: $fontSize;

  &:hover {
    background-color: $background;
    color: $hoverColor;
  }
}

// INPUTS
@mixin remove-arrows-number-input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }
}
