// COLORS
$black-dark: #111;
$black: #333;
$black-light: #4f4f4f;
$grey-dark: #828282;
$grey: #bdbdbd;
$grey-light: #e0e0e0;
$off-white: #f2f2f2;
$white: #fff;
$secondary: #001f32;
$secondary-light: #002e39;
$secondary-extra-light: #b9ccd6;
$primary: #5e7c84;
$primary-light: #c8d1d3;
$tertiary-dark: #9f915d;
$tertiary: #dad2bc;
$tertiary-light: #ece7db;
$tertiary-extra-light: #faf7f2;
$error: #cc0000;

// SHADOWS
$shadow-lg-blur: 2px 1px 5px rgba(0, 0, 0, 0.1);
$shadow-sm-blur: 0 0 10px rgba(0, 0, 0, 0.2);

$text-shadow-1: 0 0 10px rgba(0, 0, 0, 0.3);

// FONT
$ff-raleway: "Raleway", Tahoma, Geneva, Verdana, sans-serif;
$ff-merriweather: "Merriweather", Arial, Helvetica, sans-serif;

// LAYOUT
$max-site-width: 1320px;

// Z-INDEX
$z-1: 1;
$z-2: 5;
$z-3: 10;
$z-4: 20;
$z-5: 40;
$z-6: 80;
$z-7: 160;
